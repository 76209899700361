import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Section, Container, Columns, Card } from 'react-bulma-components'

// Components
import ClientQuote from 'components/client-quote'
import Separator from 'components/separator'

function Clients({ data }) {
  return (
    <div>
      <Section className="is-medium has-background-grey-lighter">
        <Container className="content has-text-centered is-widescreen">
          <Columns className="is-centered is-equal-height">
            <Columns.Column className="is-7-widescreen is-10-desktop is-10-tablet">
              <h2 className="title is-size-6 is-outlined pt-2 mt-0 mb-0">
                Our Work
              </h2>
              <h3 className="title is-size-1 is-size-1-tablet is-size-2-mobile has-text-centered mt-3">
                Creating real customer growth
              </h3>
              <p className="mb-6 is-size-4">
                We’re helping our clients design for impact on day one. As a
                result, they achieve up to 3x customer retention and 9x revenue
                growth.
              </p>
            </Columns.Column>
          </Columns>
          <Columns className="is-centered">
            {data.caseStudies.edges.map(({ node: caseStudy }) => (
              <Columns.Column
                className="is-5-widescreen is-6-desktop is-6-tablet is-12-mobile has-bg-alternating"
                key={caseStudy.slug}
              >
                <Card className="classy has-text-left">
                  <span className="circle is-bottom-left" />
                  <Columns>
                    <Columns.Column className="is-one-third-desktop">
                      <GatsbyImage
                        image={caseStudy.client.logoLight.gatsbyImageData}
                        alt="image"
                        className="is-slightly-opaque"
                      />
                    </Columns.Column>
                  </Columns>

                  {caseStudy.cardAppImage ? (
                    <GatsbyImage
                      image={caseStudy.cardAppImage.gatsbyImageData}
                      alt="image"
                      className="bottom-left resize"
                    />
                  ) : (
                    <span />
                  )}

                  <h2>{caseStudy.title}</h2>
                  <Link
                    className="button is-text mb-6 pl-0 has-text-weight-semibold"
                    to={`/case-studies/${caseStudy.slug}`}
                  >
                    View {caseStudy.client.companyName} Case Study →
                  </Link>

                  <Columns className="is-mobile">
                    <Columns.Column className="is-6-desktop" />
                    <Columns.Column className="is-6-desktop pb-6 mb-6">
                      {caseStudy.outcomes.map((outcome) => (
                        // eslint-disable-next-line react/jsx-key
                        <div>
                          <p>
                            <div className="is-size-1 is-size-2-mobile is-size-2-tablet has-text-weight-semibold mt-6">
                              {outcome.outcomeValue}
                            </div>
                            <div className="is-size-5-widescreen is-size-7-desktop is-size-7-tablet has-text-weight-semibold">
                              {outcome.summary}
                            </div>
                          </p>
                        </div>
                      ))}
                    </Columns.Column>
                  </Columns>
                </Card>
              </Columns.Column>
            ))}
          </Columns>
        </Container>
      </Section>
      <Section className="is-small pb-8">
        <Container className="content has-text-centered mt-6 pt-6 is-widescreen">
          <Columns className="is-centered">
            <Columns.Column className="is-8-desktop is-10-tablet is-12-mobile">
              <h2 className="title is-size-6 is-outlined pt-1 mt-0 mb-0">
                What we do
              </h2>
              <h3 className="title is-size-1 is-size-2-mobile has-text-centered mt-3">
                Three ways to get cracking
              </h3>
              <p className="has-text-centered mb-6 is-size-4">
                Access resources, book some consultancy or hire us as your
                development partner.
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column className="is-6-desktop is-12-tablet is-mobile">
              <Card className="classy has-bg-green has-text-left">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">Education</h2>
                <p>Get started for FREE</p>
                <p>
                  Courses, eBooks and blog posts that help you develop apps on
                  strong foundations. Learn how to do mobile right, from
                  strategy and design to development and growth marketing.
                </p>
                <StaticImage
                  height={300}
                  src="https://www.datocms-assets.com/20955/1693499805-education-card-cover.png"
                  className="hard-right is-hidden-mobile"
                />
                <Link
                  className="button is-dark is-medium is-block mt-5"
                  to="/mobile-strategy-toolkit"
                >
                  View our resources →
                </Link>
              </Card>
            </Columns.Column>
            <Columns.Column className="is-6-desktop is-12-tablet has-text-left">
              <Card className="classy has-bg-orange">
                <span className="circle is-bottom-right" />
                <h2 class="title mt-0">Advisory Services</h2>
                <p>From £5,000</p>
                <p>
                  Find digital opportunities in your business. Design innovative
                  concepts. Fix your app growth problems or get expert support
                  when building your app business case.
                </p>
                <StaticImage
                  height={300}
                  src="https://www.datocms-assets.com/20955/1693499837-consultancy-card-cover.png"
                  className="nudge-right-2 is-hidden-mobile"
                />
                <Link
                  className="button is-dark is-medium is-block mt-5"
                  to="/services"
                >
                  View our services →
                </Link>
              </Card>
            </Columns.Column>
            <Columns.Column className="is-12-desktop">
              <Card className="classy has-bg-blue has-text-left">
                <span className="circle is-top-right" />
                <span className="circle is-bottom-left" />
                <Columns>
                  <Columns.Column className="is-6">
                    <h2 class="title mt-0">App Development</h2>
                    <p>From £180,000 per year</p>
                    <p>
                      Hire us as your long-term app development partner focused
                      on quality and growth.
                    </p>
                    <ul>
                      <li>
                        Design experiences that resonate by employing
                        user-centered design and user research
                      </li>
                      <li>
                        Launch native apps or cross-platform Flutter apps
                        complete with back-end infrastructure
                      </li>
                      <li>
                        Achieve customer growth with ASO and data-driven
                        conversion optimisation
                      </li>
                    </ul>
                    <Link
                      className="button is-dark is-medium is-block mt-6"
                      to="/contact"
                    >
                      Let's Talk →
                    </Link>
                  </Columns.Column>
                  <Columns.Column className="is-5 is-offset-1 has-text-right">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1693499877-app-development-card-cover.png"
                      className="nudge-left mt-5"
                    />
                  </Columns.Column>
                </Columns>
              </Card>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </div>
  )
}

Clients.propTypes = {
  data: PropTypes.object,
}

export default Clients
