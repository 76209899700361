/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Section, Container, Hero, Columns, Box } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Separator from 'components/separator'
import ClientQuote from 'components/client-quote'

function HeroComponent({ featuredMedia, data }) {
  return (
    <>
      <Hero className="has-background-grey-lighter is-small">
        <Hero.Header>
          <SiteNav fullLogo />
        </Hero.Header>
        <Hero.Body>
          <Container className="is-widescreen">
            <div className="columns content is-vcentered is-multiline is-centered has-text-centered-mobile mt-5 pt-5">
              <div className="column is-8-desktop is-8-widescreen is-8-tablet is-12-mobile ">
                <h1 className="title is-size-6 is-outlined pt-2 mt-0 mb-0">
                  UK App Developers
                </h1>
                <h2 className="title is-size-1-widescreen is-size-2-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile is-landing-text has-text-grey-darker">
                  <dfn title="Native mobile apps, Flutter apps, web apps, iOS apps, Android apps, Desktop Apps">
                    Make apps that <br className="is-hidden-mobile" />
                  </dfn>{' '}
                  <span className="has-text-primary">make a difference.</span>
                </h2>

                <p className="is-size-3-widescreen is-size-4-desktop is-size-4-mobile">
                  We help you develop apps that create positive impact for
                  customers, society and shareholders.
                </p>
                <p className="pb-3 is-size-4 is hidden">
                  Our clients partner with us to research their markets,
                  validate ideas and then get them out into the world. Then, we
                  iterate. Product tuning, experiments and mobile marketing.
                  This is how their apps achieve more impact every day.
                </p>

                <p className="pt-5">
                  <Link
                    to="/services"
                    className="button is-primary is-medium is-leafy mr-3 mb-3"
                  >
                    View our services
                  </Link>

                  <Link
                    to="/mobile-strategy-toolkit"
                    className="button is-text is-medium mr-3 mb-3 is-hidden"
                  >
                    Mobile Success Guides →
                  </Link>
                </p>
                <p className="pb-3 is-size-5 pr-6 mr-6 is-hidden">
                  <sup className="is-hidden">
                    <i className="is-size-8 has-text-primary fa-solid fa-star-of-life" />
                  </sup>{' '}
                  <span className="tag is-grey is-size-6">New!</span> How we're
                  helping 60,000 people live with diabetes, creating 900%
                  revenue growth for Carbs & Cals.
                </p>
              </div>
              <div className="column is-4-widescreen is-4-desktop is-4-tablet is-hidden-mobile">
                <GatsbyImage
                  image={featuredMedia.gatsbyImageData}
                  alt={featuredMedia.alt || 'Pocketworks'}
                />
              </div>

              {logos()}

              <Separator className="is-hidden mt-3" rtl />
            </div>
          </Container>
        </Hero.Body>
      </Hero>
      <Section className="has-background-grey-lighter has-text-right mt-0 pt-3 is-hidden">
        <Columns className="mt-3 pb-241 pb-8 is-centered">
          {data.testimonials.edges.map(({ node: testimonial }) => (
            <Columns.Column
              key={testimonial.id}
              className="is-5-widescreen is-5-desktop is-12-tablet is-12-mobile has-text-centered content mt-5"
            >
              <ClientQuote testimonial={testimonial} key={testimonial.id} />
            </Columns.Column>
          ))}
        </Columns>
      </Section>
      {floatBoxes()}
      <Section className=" has-text-right mt-0 pt-3 is-hidden">
        <Columns className="is-centered">
          <Columns.Column className="is-8">
            <h2 className="title is-size-6 is-outlined pt-2 mb-0 mt-8">
              Testimonials
            </h2>
            <h3 className="title is-size-1 is-size-2-mobile mt-3">
              What people say
            </h3>
            <p className="pb-3 is-size-4">
              Our clients partner with us to research their markets, validate
              ideas and then get them out into the world. Then, we iterate.
              Product tuning, experiments and mobile marketing. This is how
              their apps achieve more impact every day.
            </p>
          </Columns.Column>
        </Columns>
      </Section>
    </>
  )
}

function floatBoxes() {
  return (
    <Columns className="is-centered float-overlay is-full-height ml-3 mr-3">
      {floatBox({
        title: 'A leaders guide to launching a winning mobile app',
        type: 'Illustrated eBook',
        url: '/resources/badly-drawn-mobile',
        image: (
          <StaticImage
            src="https://www.datocms-assets.com/20955/1705314399-badly-drawn-mobile-book.png?w=420&h=500&fit=crop"
            width={300}
            objectFit="fill"
          />
        ),
      })}
      {floatBox({
        title:
          'A market research report for wellbeing and mental health apps',
        type: 'Research Report',
        url: '/resources/wellbeing-apps-in-the-uk-market-report/',
        image: (
          <StaticImage
            src="https://www.datocms-assets.com/20955/1691614420-wellbeing-apps-cover.jpg?w=300&h=500&fm=jpg&fit=crop&crop=edges"
            width={300}
            objectFit="fill"
          />
        ),
      })}
    </Columns>
  )
}

function floatBox(boxData) {
  const { image } = boxData
  return (
    <Columns.Column className="is-5-widescreen is-6-desktop is-6-tablet is-12-mobile">
      <Box className="box is-rounded p-5 is-equal-height">
        <Columns className="is-vcentered is-12 is-mobile">
          <Columns.Column className="is-2-widescreen is-2-desktop is-3-tablet is-3-mobile has-text-centered">
            <div className="p-2">{image}</div>
          </Columns.Column>
          <Columns.Column className="is-10-widescreen is-10-desktop is-9-tablet is-9-mobile is-size-4-widescreen is-size-5-desktop is-size-6-tablet is-size-6-mobile">
            <div className=" pr-3">
              <span className="is-size-6 is-secondary mt-0">
                {boxData.type}
              </span>
              <br />
              {boxData.url.startsWith('http') ? (
                <a href={boxData.url} target="_blank" rel="noreferrer">
                  {boxData.title}&nbsp;→
                </a>
              ) : (
                <Link to={boxData.url}>{boxData.title}&nbsp;→</Link>
              )}
            </div>
          </Columns.Column>
        </Columns>
      </Box>
    </Columns.Column>
  )
}

function logos() {
  return (
    <Columns className="is-vcentered is-centered mp-6 pb-6 mb-6 pt-6">
      <Columns.Column className="is-offset-0 is-12-desktop is-12-mobile pb-6 mb-6">
        <Columns className="is-vcentered has-text-centered is-mobile">
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/asda-price-guarantee-app">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579558154-asda-logo-light.png?monochrome=999999&bri=-10&h=160"
                alt="ASDA - Pocketworks"
                placeholder="tracedSVG"
                height={35}
              />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/fresenius">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579558276-fresenius-logo-light.png?monochrome=999999&bri=-20&h=180"
                alt="Fresenius - Pocketworks"
                className=""
                placeholder="tracedSVG"
                height={40}
              />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/wastecare">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1710772962-wastecare-logo.png?monochrome=999999&h=80&bri=22&h=180"
                alt="WasteCare - Pocketworks"
                className="m-1"
                placeholder="tracedSVG"
                height={35}
              />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/carbs-and-cals/">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1714918076-carbs.png?monochrome=999999&h=162"
                alt="Carbs & Cals - Pocketworks"
                className=""
                placeholder="tracedSVG"
                height={35}
              />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/travelodge-prototypes">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579558227-travelodge-logo-light.png?monochrome=999999&bri=-20&h=180"
                alt="Travelodge - Pocketworks"
                className="m-1"
                placeholder="tracedSVG"
                height={35}
              />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile">
            <Link to="/case-studies/veezu-taxi-booking-mobile-apps">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120597-veezu-logo.png?monochrome=999999&bri=-10&h=100"
                alt="Veezu - Pocketworks"
                placeholder="tracedSVG"
                height={26}
              />
            </Link>
          </Columns.Column>
          <Columns.Column className="is-2-desktop is-4-mobile is-hidden">
            <Link to="/blog/krone-uk-selects-pocketworks">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1663790818-krone-logo.png?bri=-20&monochrome=999999&bri=5&h=80&fm=png"
                alt="Krone - Pocketworks"
                className="p-2"
                placeholder="tracedSVG"
                objectFit="contain"
                height={36}
              />
            </Link>
          </Columns.Column>
        </Columns>
      </Columns.Column>
    </Columns>
  )
}

HeroComponent.propTypes = {
  featuredMedia: PropTypes.object,
  data: PropTypes.object,
}

export default HeroComponent
